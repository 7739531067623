import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { commonActions } from 'app/redux/common/commonSlice';
import { setMenuItem } from '_metronic/helpers/CommonHelpers';

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v';

interface AppInitializerProps {
  children: ReactNode;
}

const casesLocation = {
  'hop-dong-khach-hang': "hopdong",
  'tham-dinh-tu-dong': "thamdinhtudong",
  'tham-dinh-chu-dong': "thamdinhchudong",
  'thanh-toan': "thanhquyettoan",
  'tien-ich': "tienichtrogiup",
  'thong-ke-bao-cao': "thongkebaocao",
  'quan-tri-he-thong': "quantrihethong",
  'danh-muc': "danhmuc"
}

const AppInitializer: React.FC<AppInitializerProps> = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentLocation } = useSelector((state: any) => state.common);
  moment.tz.setDefault("Asia/Ho_Chi_Minh");

  useEffect(() => {
    updateLocation(location.pathname);
    if (process.env.NODE_ENV === "production") {
      console.log = () => { };
    }
    handleMenuInnerWithRedirectLink()
  }, [location.pathname]);

  const handleMenuInnerWithRedirectLink = () => {
    const pathSplit = location.pathname.split("/");
    const pathSplitSecond = pathSplit[1]
    setMenuItem(casesLocation[pathSplitSecond]);
  };


  const updateLocation = (newLocation: string) => {
    dispatch(commonActions.setPrevLocation(currentLocation));
    dispatch(commonActions.setCurrentLocation(newLocation));
  };

  window.addEventListener('storage', (event) => {
    if (event.key === AUTH_LOCAL_STORAGE_KEY) {
      window.location.reload();
    }
  });

  return <>{children}</>;
};

export default AppInitializer;
