import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  pageTitle: '',
  status: 'Tạo mới',
  isSubmit: false,
  isDelete: false,
  isReset: false,
  isReload: false,
  error: {},
  cskcbDetail: {},
  isGetList: false,
  isReloadTable: false,
  isChangeStatus: {status: false, value: false, form: {}},
  statusError: null,
  search: '',
  mangLucDau: '',
  mangtoankhong: [],
  pageCurrent: {},
  menuItem: '',
  trangThaiReloadApiHanhChinh: false,
  pathLocationPrev: null,
  trangThaiDataBangChiPhi: false,
  phanHoiLanhDao: false,
  prevLocation: null,
  currentLocation: null,
  stateCollapseMenu: false,
  trangThaiNutChuyenDuyet: false,
  trangThaiReloadApiChiPhi: false,
  thongTinHanhChinh: [],
  saveStringArray: {},
  objectBoLocThanhToan: {},
  objectSearchPhieuDeNghi: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: null,
    hoTen: null,
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: null,
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
  },
  loadingFullPage: false
}

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCheckedBackBtn(state, action) {
      state.checkedBackBtn = action.payload
    },

    setPageTitle(state, action) {
      state.pageTitle = action.payload
    },
    setStatus(state, action) {
      state.status = action.payload
    },
    setStatusError(state, action) {
      state.statusError = action.payload
    },
    setIsSubmit(state, action) {
      state.isSubmit = action.payload
    },
    setIsReset(state, action) {
      state.isReset = action.payload
    },
    setIsReload(state, action) {
      state.isReload = action.payload
    },
    setIsDelete(state, action) {
      state.isDelete = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    setCskcbDetail(state, action) {
      state.cskcbDetail = action.payload
    },
    setIsChangeStatus(state, action) {
      state.isChangeStatus = action.payload
    },
    setIsGetList(state, action) {
      state.isGetList = action.payload
    },
    setSearch(state, action) {
      state.search = action.payload
    },
    setMangLucDau(state, action) {
      state.mangLucDau = action.payload
    },
    setMangToanKhong(state, action) {
      state.mangtoankhong = action.payload
    },
    setIsReloadTable(state, action) {
      state.isReloadTable = action.payload
    },
    setMenuItem(state, action) {
      state.menuItem = action.payload
    },
    setTrangThaiReloadApiHanhChinh(state, action) {
      state.trangThaiReloadApiHanhChinh = action.payload
    },
    setPathLocationPrev(state, action) {
      state.pathLocationPrev = action.payload
    },
    setTrangThaiDataBangChiPhi(state, action) {
      state.trangThaiDataBangChiPhi = action.payload
    },
    setPhanHoiLanhDao(state, action) {
      state.phanHoiLanhDao = action.payload
    },
    setPrevLocation(state, action) {
      state.prevLocation = action.payload
    },
    setCurrentLocation(state, action) {
      state.currentLocation = action.payload
    },
    setStateCollapseMenu(state, action) {
      state.stateCollapseMenu = action.payload
    },
    setTrangThaiNutChuyenDuyet(state, action) {
      state.trangThaiNutChuyenDuyet = action.payload
    },
    setTrangThaiReloadApiChiPhi(state, action) {
      state.trangThaiReloadApiChiPhi = action.payload
    },
    setThongTinHanhChinh(state, action) {
      state.thongTinHanhChinh = action.payload
    },
    setStringArray(state, action) {
      state.saveStringArray = action.payload
    },
    setObjectBoLocThanhToan(state, action) {
      state.objectBoLocThanhToan = {...state.objectBoLocThanhToan, ...action.payload}
    },
    setLoadingFullPage(state,action){
      state.loadingFullPage = action.payload
    }
  },
})

export const commonActions = commonSlice.actions

const commonReducer = commonSlice.reducer
export default commonReducer

export const selectPageTitle = (state) => state.common.pageTitle
export const selectStatus = (state) => state.common.status
export const selectStatusError = (state) => state.common.statusError
export const selectIsSubmit = (state) => state.common.isSubmit
export const selectIsReset = (state) => state.common.isReset
export const selectIsReload = (state) => state.common.isReload
export const selectIsDelete = (state) => state.common.isDelete
export const selectError = (state) => state.common.error
export const selectCskcbDetail = (state) => state.common.cskcbDetail
export const selectIsChangeStatus = (state) => state.common.isChangeStatus
export const selectIsGetList = (state) => state.common.isGetList
export const selectSearch = (state) => state.common.search
export const selectMangLucDau = (state) => state.common.mangLucDau
export const selectMangToanKhong = (state) => state.common.mangtoankhong
export const selectIsReloadTable = (state) => state.common.isReloadTable
export const selectMenuItem = (state) => state.common.menuItem
export const selectTrangThaiReloadApiHanhChinh = (state) => state.common.trangThaiReloadApiHanhChinh
export const selectTrangThaiDataBangChiPhi = (state) => state.common.trangThaiDataBangChiPhi
export const selectPhanHoiLanhDao = (state) => state.common.phanHoiLanhDao
export const selectStateCollapseMenu = (state) => state.common.stateCollapseMenu
export const selectPrevLocation = (state) => state.common.prevLocation
export const selectCurrentLocation = (state) => state.common.currentLocation
export const selectTrangThaiNutChuyenDuyet = (state) => state.common.trangThaiNutChuyenDuyet
export const selectPathLocationPrev = (state) => state.common.pathLocationPrev
export const selectTrangThaiApiChiPhi = (state) => state.common.trangThaiReloadApiChiPhi
export const selectThongTinHanhChinh = (state) => state.common.thongTinHanhChinh
export const saveStringArray = (state) => state.common.saveStringArray
export const selectBoLocThanhToan = (state) => state.common.objectBoLocThanhToan
export const selectObjectSearchPhieuDeNghi = (state) => state.common.objectSearchPhieuDeNghi
export const selectLoadingFullPage = (state) => state.common.loadingFullPage